import { createContext } from 'react';

const UserContext = createContext({
  userId: '',
  setUserId: () => {},
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  schoolName: '',
  setSchoolName: () => {},
});

export default UserContext;
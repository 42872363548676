import React, { useState } from 'react';
import UserData from './UserData';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'

const UserList = ({ users, handleDelete }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredUsers = users?.filter((user) =>
    user.Nickname.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.Email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="w-3/4 h-4/5 mx-auto flex flex-col items-center">
      <div className="w-full flex justify-center mb-4">
        <div className="relative">
          <input
            type="text"
            className="w-64 bg-gray-700 text-white rounded py-2 pl-10 pr-4"
            placeholder="Cerca..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <MagnifyingGlassIcon className="h-5 w-5 text-white absolute top-2.5 left-3" />
        </div>
      </div>
      <div className="space-y-4 overflow-auto w-full h-0 flex-1 ">
        {filteredUsers?.map((user, index) => (
          <UserData
            key={index}
            name={user.Nickname}
            email={user.Email}
            onDelete={() => handleDelete(user)}
          />
        ))}
      </div>
    </div>
  );
};

export default UserList;

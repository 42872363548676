import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL;


const useServerCalls = () => {

    const schoolLogin = async ( loginJson ) => {
        try {
            const response = await axios.post(`${apiURL}/api/schools/login`, loginJson, {
                headers: {
                    'Content-Type': 'application/json',                    
                },
            }); 

           return response.data;
          } catch (error) {
            console.error("Error creating school:", error);
            alert("Error on shool login. Please try again.");
          }
      };


    

  const generatePassword = () => {
    const length = 12;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@$%^*()_+";
    let newPassword = "";
    for (let i = 0; i < length; i++) {
      newPassword += charset[Math.floor(Math.random() * charset.length)];
    }
    // return newPassword;
    return "1";
  };

  const createUser = async (eMail, isteacher, userId, onRequestClose = () => {}, onOperationDone = () => {}) => {
    const nickname = eMail.split("@")[0].replace(".", " ");
    const generatedPassword = generatePassword();
    const newUser = {
      schoolId: userId,
      firstAccess: true,
      email: eMail,
      password: generatedPassword,
      nickname: nickname,
      isTeacher: isteacher,
      currentRoomLink: "",     
      preferredRooms: [],
      avatarJson: "{\"genderId\":1,\"typeId\":0,\"avatar\":[{\"id\":\"hair\",\"meshIndex\":0,\"materialIndex\":0},{\"id\":\"shirt\",\"meshIndex\":0,\"materialIndex\":0},{\"id\":\"legs\",\"meshIndex\":0,\"materialIndex\":0},{\"id\":\"feet\",\"meshIndex\":0,\"materialIndex\":0}],\"skinIndex\":0,\"eyesIndex\":0}",
      approvingPublishRooms: [],
      approvingDeleteRooms: [],
      approvingHideRooms: [],
      preferredItems: [],
      lastUsedItems: []
    };

    console.log(JSON.stringify(newUser));

    try {
      const response = await axios.post(`${apiURL}/api/users/create`, JSON.stringify(newUser), {
        headers: {
            'Content-Type': 'application/json',                    
        },
    }); 
      if(response !== undefined)
      {
        onRequestClose();
        onOperationDone();
      }      
    } catch (error) {
      console.error("Error creating user:", error);
      alert("Error creating user. Please try again.");
    }
  };

  const handleDelete = async (user) => {
    
    try {
      await axios.delete(
        `${apiURL}/api/users/delete/${user.ID}`
      );
      return(true);
    } catch (error) {
      console.error("Error getting data: ", error);
      alert("Error getting data: ", error);
    }

  };

  const fetchData = async ( userId, isTeacher) => {
    try {
      const response = await axios.get(
        `${apiURL}/api/users/get-studentsorteachers-from-school?schoolId=${userId}&isTeacher=${isTeacher}`
      );
      return(response.data);
    } catch (error) {
      console.error("Error getting data: ", error);
      alert("Error getting data: ", error);
    }
  };

  return {
    schoolLogin,
    createUser,
    handleDelete,
    fetchData
}
}

export default useServerCalls;
import { useContext } from "react";
import UserContext from "../contexts/UserContext";

function SchoolData()
{
    const {schoolName} = useContext(UserContext);

    return(
        <div className="bg-gray-800 min-h-screen text-white p-8">
            <p className="text-xl">{schoolName}</p>
        </div>
    );
}

export default SchoolData;
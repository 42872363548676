import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../contexts/UserContext';
import useServerCalls from '../hooks/useServerCalls';

const LoginPage = () => {
  const { setUserId, setIsLoggedIn, setSchoolName } = useContext(UserContext);
  const { schoolLogin } = useServerCalls(); 
  const [userName, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    const loginData = {
      userName,
      password
    }
    const response = await schoolLogin(JSON.stringify(loginData));
    if(response.success === true)
    {      
      setUserId(response.id);
      setIsLoggedIn(true);
      setSchoolName(response.schoolName);
      navigate('/dashboard/');
      // console.log(response)
    }
  };

  return (
    <div className="min-h-screen bg-gray-800 flex flex-col justify-center items-center text-white">
      <img src='logo-piccolo-eduportal.png' alt="Logo" className="w-48 mb-8" />
      <h1 className="text-4xl font-roboto mb-6">Login</h1>
      <form onSubmit={handleLogin} className="w-full max-w-sm">
        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2">Username:</label>
          <input
            type="text"
            value={userName}
            onChange={(e) => setUsername(e.target.value)}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-6">
          <label className="block text-white text-sm font-bold mb-2">Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
        >
          Login
        </button>
      </form>
    </div>
  );
};

export default LoginPage;
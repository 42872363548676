import React from 'react';
// import { Route, Routes, Outlet } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Sidebar from './Sidebar';
import UsersPage from './UsersPage';
import NavigationHandler from '../handlers/NavigationHandler';
import SchoolData from './SchoolData';

const Dashboard = () => {
  return (
    <div className="flex">
      <NavigationHandler />
      <Sidebar />
      <div className="flex-grow ">
        <Routes>
          <Route path="teachers" element={<UsersPage isTeacher={true} />} />
          <Route path="students" element={<UsersPage isTeacher={false} />} />
          <Route path="/*" element={<SchoolData />} />
        </Routes>
      </div>
    </div>
  );
};

export default Dashboard;

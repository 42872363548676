import React, { useState, useEffect, useContext } from "react";
import AddUserModal from "./AddUserModal";
import axios from "axios";
import UserContext from "../contexts/UserContext";
import CSVReader from "./CSVReader";
import useServerCalls from "../hooks/useServerCalls";
import UserList from './UserList';

const UsersPage = ({ isTeacher }) => {
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { userId } = useContext(UserContext);

  const { createUser, handleDelete, fetchData } = useServerCalls();

  const handleFetchData = () => {    
    const getData = async () => {
      
      const response = await fetchData(userId, isTeacher);
      console.log(response);
      setUsers(response);
      
    };
    getData();
  };

  useEffect(() => {
    const getData = async () => {
      
      const response = await fetchData(userId, isTeacher);
      console.log(response);
      setUsers(response);
      
    };
    getData();
  }, [userId, isTeacher]);

  const handleParseDone = (parsedArray) => {
    Promise.all(
      parsedArray.map(async (userEmail) => {
        await createUser(userEmail, isTeacher, userId, handleFetchData());
      })
    )
      .then(() => {
        alert("Tutti gli utenti sono stati creati con successo.");
      })
      .catch((error) => {
        console.error("Errore durante la creazione degli utenti:", error);
      });
  };

  const handleDeleteButton = async (user) => {
    console.log(user.ID);

    const response = await handleDelete(user);
    if(response === true){
      handleFetchData();
    }
  };

  const userType = isTeacher ? "Insegnanti" : "Studenti";
  const addUserLabel = isTeacher
    ? "Aggiungi Insegnante"
    : "Aggiungi Studente";

    return (
      <div className="bg-gray-800 min-h-screen text-white py-8">
        <div className="container mx-auto flex flex-col items-center">
          <div className="flex flex-col items-center mb-6">
            <h1 className="text-4xl font-bold mb-10">{userType}</h1>
            <div className="flex items-center mb-6">
              <button
                onClick={() => setIsModalOpen(true)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4"
              >
                {addUserLabel}
              </button>
              <CSVReader onParseDone={handleParseDone} />
            </div>
          </div>
          <AddUserModal
            isOpen={isModalOpen}
            onRequestClose={() => {
              setIsModalOpen(false);
              handleFetchData();
            }}
            isTeacher={isTeacher}
          />
          <UserList users={users} handleDelete={handleDeleteButton} />
        </div>
      </div>
    );
            
  }
export default UsersPage;

import React, { useState} from 'react';
import { TrashIcon } from '@heroicons/react/24/solid'

const UserData = ({ name, email, onDelete }) => {
  const [showModal, setShowModal] = useState(false);

  const handleConfirmDelete = () => {
    onDelete();
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
        <div
          className="fixed inset-0 bg-black opacity-50"
          onClick={() => setShowModal(false)}
        ></div>
        <div className="fixed z-10 inset-0 overflow-y-auto text-black">
          <div className="flex items-center justify-center min-h-screen">
            <div className="bg-white w-96 p-6 rounded shadow-lg">
              <h2 className="text-xl font-bold mb-4">Conferma eliminazione</h2>
              <p>Sei sicuro di voler eliminare <b>{name}</b>?</p>
              <div className="flex justify-end mt-4">
                <button
                  className="bg-gray-300 text-gray-900 rounded px-4 py-2 mr-2"
                  onClick={() => setShowModal(false)}
                >
                  Annulla
                </button>
                <button
                  className="bg-red-500 text-white rounded px-4 py-2"
                  onClick={handleConfirmDelete}
                >
                  Conferma
                </button>
              </div>
            </div>
          </div>
        </div>
        </div>
      )}
      <div className="w-full border-b border-gray-300 py-2 flex items-center">
        <div className="flex-grow">
          <h3 className="font-semibold">{name}</h3>
          <p className="text-gray-600">{email}</p>
        </div>
        <button
          className="text-red-500 hover:text-red-700 ml-4"
          onClick={() => setShowModal(true)}
        >
          <TrashIcon className="h-6 w-6" />
        </button>
      </div>
    </>
  );
};

export default UserData;
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { List, ListItem, ListItemText } from '@mui/material';
import './Sidebar.css';
import UserContext from '../contexts/UserContext';

const Sidebar = () => {
  const { setUserId, setIsLoggedIn } = useContext(UserContext);

  const handleLogout = () => {
    setUserId('');
    setIsLoggedIn(false);
  };

  return (
    <div className="bg-gray-800 text-white min-h-screen sticky top-0 flex flex-col h-full">
      <List className="flex flex-col justify-between h-full">
          <ListItem>
            <img src="/logo-piccolo-eduportal.png" alt="Logo" className="sidebar-logo" />
          </ListItem>
          <ListItem button component={NavLink} to="/dashboard/" activeClassName="bg-blue-500">
            <ListItemText primary="Account" />
          </ListItem>
          <ListItem button component={NavLink} to="/dashboard/teachers" activeClassName="bg-blue-500">
            <ListItemText primary="Insegnanti" />
          </ListItem>
          <ListItem button component={NavLink} to="/dashboard/students" activeClassName="bg-blue-500">
            <ListItemText primary="Studenti" />
          </ListItem>
        <ListItem className='align-baseline'>
          <button
            onClick={handleLogout}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
          >
            Logout
          </button>
        </ListItem>
      </List>
    </div>
  );
};

export default Sidebar;
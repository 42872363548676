import React, { useRef } from 'react';
import Papa from 'papaparse';

const CSVReader = ( {onParseDone} ) => {
    const fileInputRef = useRef(null);
    const handleButtonClick = () => {
        fileInputRef.current.click();
      };

    const handleFileChange = (event) => {
    const file = event.target.files[0];

    Papa.parse(file, {
      header: false,
      complete: (results) => {
        const firstColumnArray = results.data.map((row) => row[0]);
        // onParseDone(JSON.stringify(firstColumnArray, null, 2));
        onParseDone(firstColumnArray);
      },
      error: (err) => {
        console.error('Errore nel parsing del file CSV:', err);
      },
    });
  };

  return (
    <div>
      <input
        type="file"
        accept=".csv"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        ref={fileInputRef}
        id="csvFileInput"
      />
      <button onClick={handleButtonClick} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4">
        Carica da file
      </button>
      {/* {csvArray.length > 0 && (
        <div>
          <h3>Array da CSV:</h3>
          <pre>{JSON.stringify(csvArray, null, 2)}</pre>
        </div>
      )} */}
    </div>
  );
};

export default CSVReader;

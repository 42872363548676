import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import UserContext from '../contexts/UserContext';

function NavigationHandler() {
  const { isLoggedIn } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if ( isLoggedIn ) {
        // navigate('/dashboard');
    } else {
        navigate('/');
    }
  }, [isLoggedIn, navigate]);

  return null;
}

export default NavigationHandler;

import React, {useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/LoginPage';
import Dashboard from './components/Dashboard';
// import CreateSchool from './components/CreateSchool'
import UserContext from './contexts/UserContext';

function App() {
  const [userId, setUserId] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [schoolName, setSchoolName] = useState('');


  

  return (
    <UserContext.Provider value={{ userId, setUserId, isLoggedIn, setIsLoggedIn, schoolName, setSchoolName }}>
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/dashboard/*" element={<Dashboard />} />
          </Routes>
        </div>
      </Router>
    </UserContext.Provider>
  );
}

export default App;
import React, { useContext, useState } from "react";
import Modal from "react-modal";
import UserContext from "../contexts/UserContext";
import useServerCalls from "../hooks/useServerCalls";

Modal.setAppElement("#root");

const AddTeacherModal = ({ isOpen, onRequestClose, isTeacher }) => {
  const { userId } = useContext(UserContext);
  const { createUser } = useServerCalls();
  const [inputEmail, setInputEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    createUser(inputEmail, isTeacher, userId, onRequestClose, handleOperationDone);
  };

  const handleOperationDone = () => {
    setInputEmail('');
    if(isTeacher) {
      alert("Insegnante aggiunto correttamente");
    }
    else{
      alert("Studente aggiunto correttamente");
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="bg-white text-black w-11/12 md:max-w-md mx-auto rounded py-6 px-4"
      overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center"
    >
      {isTeacher && 
      <h2 className="text-2xl font-bold mb-4">Aggiungi Insegnante</h2>
    }
      {!isTeacher &&
      <h2 className="text-2xl font-bold mb-4">Aggiungi Studente</h2>
      }
      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="email"
          placeholder="Email"
          value={inputEmail}
          onChange={(e) => setInputEmail(e.target.value)}
          required
          className="w-full p-2 border border-gray-300 rounded"
        />
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
        >
          Conferma
        </button>
      </form>
    </Modal>
  );
};

export default AddTeacherModal;